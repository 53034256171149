.app {
  text-align: center;
}

.header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.pfyhheader {
  background-color: #1b1e23;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.impressum {
  background-color: #1b1e23;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.impressumMobile {
  background-color: #1b1e23;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 8px;
}

.headline
{
  font-weight: bold;
  font-size: 100px;
}

.headlineMobile
{
  font-weight: bold;
  font-size: 60px;
}

.button
{
  background-color: #0085dd;
  position: absolute;
  border: none;
  left: calc(50% - 250px);
  width: 500px;
  height: 50px;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  letter-spacing: 3px;
  border-radius: 7px;
  box-shadow: 4px 4px 7px #6e00c8;
}

.buttonMobile
{
  background-color: #0085dd;
  position: absolute;
  border: none;
  left: calc(50% - 150px);
  width: 300px;
  height: 50px;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  letter-spacing: 3px;
  border-radius: 7px;
  box-shadow: 4px 4px 7px #6e00c8;
}

.button:hover
{
  background-color: #e0f3ff;
  color: black;
  /* font-weight: bold; */
  cursor: pointer;
}